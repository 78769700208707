.component-intro-quote-text {
    .container-xl {
        max-width: 1240px;
    }
    ul {
        list-style: disc;
        margin-bottom: 1em;
        padding-inline-start: 24px;
    }
    ol {
        margin-bottom: 1em;
        padding-inline-start: 24px;
    }
}

.component-headline + .component-intro-quote-text {
    margin-top: calc(-48px + 16px - 48px);
}
