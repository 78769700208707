.clickable-box {

}
.mw-1920 {
    max-width: 1920px;
}

.mw-1200 {
    max-width: 1200px;
}

.mw-1240 {
    max-width: 1240px;
}
