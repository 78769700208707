//forms
button.button,
.button {
    font-family: $sans-serif-bold, sans-serif;
    font-size: 14px;
    line-height: 21px;
    line-height: 1;
    font-weight: 600;
    color: $color-white;
    padding: 14px;
    border-radius: 5px;
    background-color: $color-blue;
    min-height: 42px;
    width: 180px;
    border: none;
    display: block;
    text-align: center;
}

button.button:hover,
.button:hover {
    background-color: rgba($color-blue, 0.9);
}
button.button:active,
.button:active {
    box-shadow: 0px 0px 15px rgba($color-black, 0.78) inset;
}

.button.button-white {
    color: $color-blue;
    background-color: $color-white;
}
.button.button-white:hover {
    background-color: rgba($color-white, 0.9);
}
.button.button-white:active {
    box-shadow: 0px 0px 15px rgba($color-black, 0.78) inset;
}

.button-small {
    font-family: 'DIN-Bold', sans-serif;
    font-size: 14px;
    line-height: 21px;
    line-height: 1;
    font-weight: 600;
    color: $color-white;
    padding: 5px;
    border-radius: 5px;
    background-color: transparent;
    min-height: 26px;
    width: 140px;
    border: 1px solid $color-white;
    display: block;
    text-align: center;
}
.button-small:hover {
    color: rgba($color-white, 0.5);
    border: 1px solid rgba($color-white, 0.5);
}
.button-small.button-small-blue {
    color: $color-blue;
    border: 1px solid $color-blue;
}
.button-small.button-small-blue:hover {
    color: rgba($color-blue, 0.5);
    border: 1px solid rgba($color-blue, 0.5);
}


.border-blue {
    border: 1px solid $color-blue;
}
.button-fine {
    font-family: $sans-serif;
    font-size: 16px;
    display: inline-block;
    padding: 12px;
    border-radius: 5px;
    line-height: 1;
    margin-right: 4px;
    margin-bottom: 8px;
}



.arrow-down {
    border-color: $color-blue transparent transparent;
    border-style: solid;
    border-width: 10px 6px 0;
    height: 0;
    right: 10px;
    position: absolute;
    top: calc(50% - 6px);
    width: 0;
    transform: rotate(0deg);
}
.filterbutton {
    font-family: $sans-serif, sans-serif;
    font-size: 16px;
    line-height: 22px;
    line-height: 1;
    font-weight: 400;
    color: $color-blue;
    padding: 12px;
    border-radius: 5px;
    background-color: $color-white;
    min-height: 40px;
    border: 1px solid rgba($color-grey, 0.5);
    display: block;
    text-align: left;
}
.selection {
    background-color: $color-white;
    min-height: 42px;
    .filterbutton {
        border-radius: 5px;
        border: 1px solid rgba($color-grey, 0.5);
    }
    .filterbutton:hover {
        border: 1px solid rgba($color-gold, 1);
    }
    ul {
        //display: none;
        padding: 12px;
        max-height: 250px;
        min-height: 120px;
        overflow: auto;
        margin-bottom: 5px;
        li label {
            font-size: 15px;
            padding: 5px;
            padding-left: 0;
            color: $color-blue;
        }
        li label:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        li label:last-child {
            padding-bottom: 0;
        }
    }

    .ul-lawyernames {
        max-height: 125px;
        min-height: auto;
    }

    .filterbutton.show + ul {
        margin-top: -10px !important;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .filterbutton.show .arrow-down {
        transform: rotate(180deg);
    }
}

.pill {
    background-color: $color-white;
    border: 1px solid $color-gold;
    border-radius: 10px;
    color: $color-gold;
    font-family: $sans-serif-bold;
    font-size: 16px;
    line-height: 1;

    display: inline-flex;
    align-items: center;

    position: relative;
    padding: 1px 10px 1px 0px;
    cursor: pointer;
    letter-spacing: 0;
    font-size: .875rem;
    line-height: 1;
    border-radius: 5px;


    svg {
        width: 30px;
        height: 30px;
        margin-top: -2px;
        margin-right: 2px;
        transform: rotate(45deg);
    }
    margin-right:20px;
    margin-bottom:20px;

    @include media-breakpoint-up(lg) {
        margin-bottom:40px;
    }
}
.pill:hover {
    cursor: pointer;
}
#searchemployees {
    border: none;
    color: $color-blue;
    border-bottom: 2px solid $color-blue;
    padding-left: 4px;
    height: 42px;
}
#searchemployees::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-blue;
    opacity: 1; /* Firefox */
}
input:focus::placeholder {
    color: transparent !important;
}
input:focus,
input:active:focus,
input.active:focus,
input.focus,
input:active.focus,
input.active.focus {
    outline: none !important;
}

.event__registration {
    .fui-legend, .fui-label, .fui-required {
        color: #002e60;
    }
    .content__form input[type=email],
    .content__form input[type=tel] {
        border: 1px solid #002e60;
        border-radius: 19px;
        padding: 6px 10px 3px 15px;
    }
    .fui-btn.fui-submit {
        background: #002e60;
        border-color: #002e60;
    }
    .fui-alert.fui-alert-success {
        background-color: transparent;
        color: #002e60;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 22px;
        font-weight: normal;
    }
}

.newsletter__registrion {
    --fui-label-margin: 0.25rem;
    --fui-row-gutter: 0.75rem;

    .fui-legend, .fui-label, .fui-required {
        color: $color-green;
    }
    .fui-input.fui-focus,
    .fui-input:focus,
    .fui-select.fui-focus,
    .fui-select:focus {
        border: 1px solid $color-green;
    }

    .fui-input {
        border-radius: 19px;
        padding: 6px 10px 3px 15px;

        &:hover,
        &:focus,
        &:active {
            box-shadow: 0 0 0 3px rgba($color-green, 0.2) !important;
        }
    }

    .content__form input[type="text"]
    .content__form input[type="email"],
    .content__form input[type="tel"] {
        border: 1px solid $color-green;
        border-radius: 20px;
        padding: 4px 14px;

        &:focus {
            border-color: $color-green;
            box-shadow: none !important;
        }
    }
    .fui-btn.fui-submit {
        background: $color-green;
        border-color: $color-green;
    }
    .fui-alert.fui-alert-success {
        background-color: transparent;
        color: $color-green;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 22px;
        font-weight: normal;
    }

    .fui-radio input,
    .fui-checkbox input {
        border-color: $color-green;
        box-shadow: 0 0 0 3px rgba($color-green, 0.2) !important;

        &:checked {
            &+ label {
                &:before {
                    border-color: $color-green;
                    box-shadow: 0 0 0 3px rgba($color-green, 0.2) !important;
                    background-color: $color-green;
                }
            }
        }

        &:focus,
        &:active {
            &+ label {
                &:before {
                    border-color: $color-green;
                    box-shadow: 0 0 0 3px rgba($color-green, 0.2) !important;
                }
            }
        }
    }
    
    .fui-btn.fui-submit {
        border-radius: 20px;
        margin: 0 auto;

        &:focus {
            box-shadow: 0 0 0 3px rgba($color-green, 0.2) !important;
        }
    }

    .fui-alert {
        padding: 1rem;
    }
}