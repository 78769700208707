html {
    touch-action: manipulation;
    box-sizing: border-box;
    color: $color-blue;
    font-family: $sans-serif;
    font-size: 16px;
    height: 100%;
}
body {
    color: $color-blue;
    font-family: $sans-serif;
    font-size: 16px;
    min-height: 100%;
    background-color: #fbfbfb;
}
main, header {
    max-width: 1920px;
    margin: 0 auto;
}
main {
    background-color: #f4f4f4;
    min-height: calc(100vh - 240px);
    padding-bottom: 50px;
}
.layout__main + main {
    min-height: auto;
}
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: 50px;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}
.cursor-pointer:hover {
    cursor: pointer;
}
.border-radius {
    border-radius: 5px;
}
.text-hyphens {
    hyphens: auto;
    hyphenate-limit-chars: 6 3 2;
}
