.component-themeblocks {

    .container-xl {
        padding-top:24px;
        padding-bottom:24px;
        max-width: 1240px;
    }

    .component-themeblocks-headline p {
        font-size: 16px;
        @include media-breakpoint-up(md) {
            font-size: 32px;
        }
    }

    .component-themeblocks-text p {
        font-size: 14px;
        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }

}
