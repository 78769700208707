.layout__shop {

    .content__shop {

        h1 {
            font-family: DIN-Bold, Arial, sans-serif;
            line-height: 38px;
            font-size: 16px;
            @media (min-width: 768px) {
                font-size: 32px;
            }
        }

        h2 {
            font-size: 1.5rem !important;
            line-height: 1.5rem !important;
        }
        h3 {
            font-size: 1.2rem !important;
            line-height: 1.2rem !important;
        }
    }

    .text-red-600 {
        color: red;
    }

}
