.component-circleMenu {

    .minus, .component-circleMenu-content {
        display: none;
    }
    .component-circleMenu-single.active {
        .plus {
            display: none;
        }
        .minus {
            display: inline;
        }
    }
    .component-circleMenu-single.inactive {
        opacity: 0.35;
    }
    .component-circleMenu-single.active + .component-circleMenu-content {
        display: flex;
    }
    .component-circleMenu-single:hover {
        cursor: pointer;
    }

}

.order-5 { order: 5 !important; }
.order-6 { order: 6 !important; }
.order-7 { order: 7 !important; }
.order-8 { order: 8 !important; }
.order-9 { order: 9 !important; }
.order-10 { order: 10 !important; }
.order-11 { order: 11 !important; }
.order-12 { order: 12 !important; }
@include media-breakpoint-up(lg) {
    .component-circleMenu .order-lg-4 {
        order: 4 !important;
    }
    .component-circleMenu .order-lg-10 {
        order: 10 !important;
    }
}
