.page--career-index .layout__main,
.page--career-index .layout__main a,
.page--career-index .select2-container--default .select2-results__option--highlighted[aria-selected],
.page--career-index .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #002e60 !important;
}
html.page--career-detail .select2-container--default .select2-selection__arrow b,
htmlGAG-88 Komponente Text-Bild-Split.page--career-index .select2-container--default .select2-selection__arrow b {
    border-color: #002e60 transparent transparent !important;
}

html.page--career-index .select2-container--open .select2-selection__arrow b {
    border-color: transparent transparent #002e60 !important;
}


.page--career-detail .main__row__col__header-basic {
    border-color: #002e60 !important;
}

.page--career-detail .layout__main, .page--career-detail .layout__main a,
.page--career-detail .select2-container--default .select2-results__option--highlighted[aria-selected],
.page--career-detail .select2-container--default .select2-selection--single .select2-selection__placeholder,
.page--career-index .layout__main, .page--career-index .layout__main a,
.page--career-index .select2-container--default .select2-results__option--highlighted[aria-selected],
.page--career-index .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #002e60 !important;
}
html.page--career-detail .main__row__col:last-child .main__row__col__text-sidebar {
    border-top: 1px solid #002e60 !important;
}
html.page--career-detail .select2-container--open .select2-selection__arrow b, .page--career-index .select2-container--open .select2-selection__arrow b {
    border-color: transparent transparent #002e60 !important;
}
