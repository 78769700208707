.component-thematicAreas {

    .container-xl {
        max-width: 1280px;
    }

    .component-thematicAreas-area {
        h3 {
            font-family: $sans-serif-bold, sans-serif;
            font-weight: 600;
            font-size: 22px;
        }
        border-bottom: 1px solid $color-grey;
        margin-bottom: 16px;
        @include media-breakpoint-up(lg) {
            border-right: 1px solid $color-grey;
            border-bottom: none;
            padding-left: 32px;
            padding-right: 32px;
        }
    }
    .component-thematicAreas-area-last {
        border-color: transparent;
    }
    .component-thematicAreas-area-icon {
        flex: 1 0 auto;
        //height: 30px;
        //width: 30px;
        height: 52px;
        width: 52px;
        margin-right: 32px;
        margin-bottom: 32px !important;
    }
}
