.video-slider {

    .splide__pagination {
        position: relative;
        margin-top: 20px;
    }
    .splide__pagination__page {
        background: transparent;
        width: 28px;
        border-radius: 0;
        height: 5px;
        border-bottom: 2px solid #707070;
    }

    .splide__pagination__page.is-active {
        background: #8d8045;
        border-bottom: 2px solid #8d8045;
        transform: none;
    }
    

    .splide__arrows {
        left: 0;
        position: absolute;
        top: calc(50%);
        width: 100%;
        .splide__arrow--prev {
            left: -30px;
            opacity: 1;
            background: #0000;
        }
        .splide__arrow--next {
            opacity: 1;
            right: -30px;
            background: #0000;
        }
    }

    .splide__slide {
        .splide-background-wrapper {
            background: linear-gradient(to bottom, transparent, black 75%);
            height: 40%;
        }
        .splide-text-wrapper h3 {
            text-align: left;
            font-size: 20px;
            line-height: 1.375;
            @include media-breakpoint-up(lg) {
                font-size: 22px;
            }
        }
        .splide-text-wrapper {
            border-top:1px solid $color-gold;
        }
        .splide-text-wrapper p {
            text-align: left;
            font-size: 14px;
        }
        .img-wrapper {
            aspect-ratio: 6 / 11;
            box-shadow: inset 0px 12px 9px -6px rgba(0,0,0,0.25);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .play-wrapper {
            width: 50px;
            height: 50px;
            position: absolute;
            margin-top: -60px;
            margin-left: 20px;
            border:none;
            background: transparent;
        }
        .play {
            width: 50px;
            height: 50px;
        }
        
    }

}

body.modal-open .layout__main {
    z-index: auto;
}
.lh-0 {
    line-height: 0;
}

@include media-breakpoint-up(md) {
    #videoModal .modal-dialog, .videoModal .modal-dialog {
        max-width: 80% !important;
        @include media-breakpoint-up(xl) {
            max-width: 800px !important;
        }
    }
}
