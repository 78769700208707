@use 'sass:map';

// Variables
@use './utils/svg';

// bootstrap shade-color function
@function shade-color($color, $weight) {
    @return mix(black, $color, $weight);
}


// Enabling / Disabling dark mode
$enable-dark-mode: false;

// Grid System
$container-max-widths: (
    sm: 475px,
    md: 720px,
    lg: 992px,
    xl: 1220px,
    xxl: 1840px
);
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1920px
);

// Spacings
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
);
