.component-cta-wrapper  {

}
.component-copytext.bg-white + .component-cta-wrapper.bg-white {
    margin-top: calc(-48px + 48px);
}
.component-intro-quote-text.bg-white + .component-cta-wrapper.bg-white {
    margin-top: calc(-48px + 32px);
}

.component-copytext.bg-white + .component-cta-wrapper.bg-white.distanceToTopSmall {
    margin-top: calc(-48px + 32px);
}
.component-intro-quote-text.bg-white + .component-cta-wrapper.bg-white.distanceToTopSmall {
    margin-top: calc(-48px + 16px);
}

.component-copytext.bg-white + .component-cta-wrapper.bg-white.distanceToTopLarge {
    margin-top: 0;
    padding-top: 16px;
}
.component-intro-quote-text.bg-white + .component-cta-wrapper.bg-white.distanceToTopLarge {
    margin-top: calc(-48px + 48px);
}
