.dropdown {

    .filterbutton.isActive {
        border-bottom:0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .arrow-down {
            transform: rotate(180deg);
        }
        border-color: rgba($color-gold, 1);
    }

    .filterbutton-label-wrapper {
        display: none;
        position: absolute;
        background-color: $color-white;
        margin-top:0px;
        max-height: 200px;
        overflow: auto;
        padding: 12px 12px 6px 12px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: 1px solid rgba($color-gold, 1);
        label {
            font-size: 16px;
        }
        label:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .filterbutton.isActive + .filterbutton-label-wrapper {
        display: block;
        z-index: 1;
    }
}
