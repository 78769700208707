.hero__wrapper-new {
    min-height: 220px;
    .hero__bgimage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    @media (min-width: 768px) {
        align-items: end!important;
        padding-bottom: 4rem;
    }
}
