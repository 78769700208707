.body-index-news {
    background-color: $color-grey-extralight;
}

.overview-news {

    .row-news {
        //max-width: 960px;
        // margin: auto;
    }
    
    .news__list-item-link {
        border: 1px solid $color-white;
        background-color: $color-white;
        border-radius: 5px;
        padding: 16px 20px;
    }

    .news__list-item-link:hover{
        border:1px solid $color-gold;
    }
    .news__list-item-category {
        font-size: 12px;
        margin-bottom: 1.2em;
        padding-bottom: 0.6em;
        line-height: 1.2;
        color: #8d8045;
        border-bottom: 1px solid #c6bc8b;
    }
    .news__list-item-date {
        color: #8d8045;
        display: block;
        font-size: 12px;
        margin-bottom: 0.8em;
        font-family: DIN-Regular,Arial,sans-serif;
    }

    .news__list-item-title {
        font-size: 16px;
        line-height: 1.375;
        text-decoration: none;
        max-width: 90%;
        display: inline-block;
    }
}
