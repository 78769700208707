// Fontsize Fixes & Overrides (what's not possible with Bootstrap)

$system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
"Segoe UI Emoji", "Segoe UI Symbol";
// $sans-serif-light: 	'DIN-Light',Arial, sans-serif;
$sans-serif: 'DIN-Regular', Arial, sans-serif;
$sans-serif-medium: 'DIN-Medium', Arial, sans-serif;
$sans-serif-bold: 'DIN-Bold', Arial, sans-serif;
$serif: 'Ascender Serif Medium', Arial, sans-serif;

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    font-family: inherit;
    text-rendering: optimizelegibility;
    font-weight: 400;
    color: inherit;
    margin-top: 0;
    margin-bottom: 0;
    line-height: inherit;

    a {
        text-decoration: none !important;
    }
}


/*********************
LINK STYLES
*********************/

a {
    color: $link-color;
    text-decoration: none;

    /* on hover */
    &:hover,
    &:focus {
        text-decoration: none;
    }

    /* on click */
    &:active {
    }

    &:focus {
        outline: 0;
    }

    /* mobile tap color */
    &:link {
        /*
        this highlights links on iPhones/iPads.
        so it basically works like the :hover selector
        for mobile devices.
        */
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
    }
}

/*********************
NORMALIZE / GENERAL STYLES
*********************/

hr,
.hr {
    border: 1px solid;
    margin: -1px 0;
    opacity: 1;
}

sub,
sup {
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

b,
strong {
    font-weight: 600;
    font-family: 'DIN-Bold', sans-serif;
}

em,
i {
    font-style: italic;
}

img {
    max-width: 100%;
    height: auto;
}

iframe {
    max-width: 100%;
    width: 100%;
}

hr {
    width: 100%;
    height: 1px;
    border: none;
    background: #eee;
}

p,
ol,
ul {
    line-height: 1.375;
    margin-top: 0;
    margin-bottom: 1em;
}

ul,
ol {
    padding: 0;
    line-height: 1.375;
    margin: 0;
}

ul {
    list-style: none;
}
svg {
    fill: currentColor;
}

h1.h1-header {
    font-family: $sans-serif, sans-serif;
    hyphens: auto;
    font-size: 24px;
    line-height: 34px;
    @include media-breakpoint-up(md) {
        hyphens: none;
        font-size: 28px;
        line-height: 40px;
    }
}
h2.h2-claim {
    font-family: $sans-serif-bold, sans-serif;
    font-size: 32px;
    line-height: 38px;
    hyphens: auto;
    @include media-breakpoint-up(md) {
        hyphens: none;
        font-size: 45px;
        line-height: 58px;
    }
}
