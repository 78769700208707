.entry-lawyer {

    svg.icon-pdf {
        height: 31px;
    }

    .container-lawyer-back {
        min-height: 72px;
    }
    @include media-breakpoint-up(xl) {
        .row {
            //max-width: 1280px;
            //margin-left: auto;
            //margin-right: auto;
        }
    }


    h1 {
        font-family: $sans-serif-bold, sans-serif;
        font-size: 32px;
        line-height: 38px;
        @include media-breakpoint-up(md) {
            font-size: 64px;
            line-height: 76px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 80px;
            line-height: 95px;
        }
    }
    .h1 {
        font-family: $sans-serif-bold, sans-serif;
        font-size: 32px;
        line-height: 38px;
    }
    h2, .h2 {
        font-family: $sans-serif-bold, sans-serif;
        font-size: 22px;
        line-height: 25px;
    }
    .h2-light {
        font-family: $sans-serif, sans-serif;
    }
    .container-fluid {
        padding-left:36px;
        padding-right:36px;
    }
    .chevron-backlink {
        height: 11px;
        width: 8px;
        margin-top:-2px;
        margin-right: 12px;
    }
    .employee-photo {
        margin-top:36px;
        margin-bottom:36px;
    }
    .employee-data {
        margin-bottom:36px;
        @include media-breakpoint-up(md) {
            margin-top:36px;
        }
    }
    .employee-data-practiceareas,
    .employee-badges {
        margin-top:36px;
        margin-bottom:36px;
    }
    .employee-data-text {
        margin-bottom:48px;
    }

    #accordionNewsPubl {
        .accordion-item {
            border-top: 1px solid $color-gold;
        }
        .accordion-item:last-child {
            border-bottom: 1px solid $color-gold;
        }
        .cross {
            height: 30px;
            width: 30px;
            fill: $color-gold;
        }

        .accordion-button {
            .cross {
                transform: rotate(45deg);
                transition: all 0.3s;
                border: 1px solid $color-gold;
                border-radius: 30px;
            }
            h1 {
                color: $color-gold;
            }
        }
        .accordion-button.collapsed {
            .cross {
                transform: rotate(0deg);
                transition: all 0.3s;
            }
            h1 {
                color: $color-blue;
            }
        }
        .w-80px {
            width: 80px;
        }
        .color-grey {
            line-height: 1.2;
            a {
                color: #898989 !important;
                text-decoration: underline;
            }
        }
    }

    .splide__arrows {
        position: absolute;
        top: calc(50% - 40px);
        width: 100%;
        left: 0;
    }
    .splide__arrow {
        background: transparent;
    }
    .splide__arrow--prev {
        left:-30px;
        opacity: 1;
    }
    .splide__arrow--next {
        right:-30px;
        opacity: 1;
    }

    .quote {
        font-size: 22px;
        line-height: 31px;
        font-family: $sans-serif-medium, sans-serif;
        padding-bottom: 30px;
    }

}
.entry-lawyer-slider,
.layout__main-startuplaw {

    .container-lawyer-back {
        min-height: 72px;
    }
    h1 {
        font-family: $sans-serif-bold, sans-serif;
        font-size: 32px;
        line-height: 38px;
        @include media-breakpoint-up(md) {
            font-size: 64px;
            line-height: 76px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 80px;
            line-height: 95px;
        }
    }
    .h1 {
        font-family: $sans-serif-bold, sans-serif;
        font-size: 32px;
        line-height: 38px;
    }
    h2, .h2 {
        font-family: $sans-serif-bold, sans-serif;
        font-size: 22px;
        line-height: 25px;
    }
    .h2-light {
        font-family: $sans-serif, sans-serif;
    }
    .container-fluid {
        padding-left:36px;
        padding-right:36px;
    }
    .chevron-backlink {
        height: 11px;
        width: 8px;
        margin-top:-2px;
        margin-right: 12px;
    }
    .employee-photo {
        margin-top:36px;
        margin-bottom:36px;
    }
    .employee-data {
        margin-bottom:36px;
        @include media-breakpoint-up(md) {
            margin-top:36px;
        }
    }
    .employee-data-practiceareas,
    .employee-badges {
        margin-top:36px;
        margin-bottom:36px;
    }
    .employee-data-text {
        margin-bottom:48px;
    }

    #accordionNewsPubl {
        .accordion-item {
            border-top: 1px solid $color-gold;
        }
        .accordion-item:last-child {
            border-bottom: 1px solid $color-gold;
        }
        .cross {
            height: 30px;
            width: 30px;
            fill: $color-gold;
        }

        .accordion-button {
            .cross {
                transform: rotate(45deg);
                transition: all 0.3s;
                border: 1px solid $color-gold;
                border-radius: 30px;
            }
            h1 {
                color: $color-gold;
            }
        }
        .accordion-button.collapsed {
            .cross {
                transform: rotate(0deg);
                transition: all 0.3s;
            }
            h1 {
                color: $color-blue;
            }
        }
        .w-80px {
            width: 80px;
        }
    }


    .quote {
        font-size: 22px;
        line-height: 31px;
        font-family: $sans-serif-medium, sans-serif;
        padding-bottom: 30px;
    }
}

.product-slider {

    .splide__pagination {
        position: relative;
        margin-top: 20px;
    }
    .splide__pagination__page {
        background: transparent;
        width: 28px;
        border-radius: 0;
        height: 5px;
        border-bottom: 2px solid #707070;
    }

    .splide__pagination__page.is-active {
        background: #8d8045;
        border-bottom: 2px solid #8d8045;
        transform: none;
    }


    .splide__arrows {
        position: relative;
        @include media-breakpoint-up(lg) {
            max-width: 50%;
        }
    }
    .splide__arrow {
        background: transparent;
    }
    .splide__arrow--prev,
    .splide__arrow--next {
        top:64px;
    }

    .splide-text-wrapper {
        height: 130px;
        border-top: 1px solid $color-gold;
        h3 {
            font-size: 28px;
            line-height: 40px;
            text-align: left;
        }
    }

    .img-wrapper {
        aspect-ratio:376/467;
        background-repeat:no-repeat;
        background-position:center;
        background-size:cover;
    }

}

.employee-badges-inner-wrapper {
    flex: 0 0 calc(50% - 24px);
    @include media-breakpoint-up(lg) {
        flex: 0 0 calc(25% - 24px);
    }
    margin: auto;
    margin-bottom: 24px;
}
