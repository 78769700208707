@font-face {
    font-family: 'Ascender Serif Medium';
    src: url("../../fonts/AscenderSerifMedium.eot");
    src: url("../../fonts/AscenderSerifMedium.eot?#iefix") format("embedded-opentype"), url("../../fonts/AscenderSerifMedium.woff") format("woff"), url("../../fonts/AscenderSerifMedium.ttf") format("truetype");
    font-style: normal;
    font-weight: 400; }
@font-face {
    font-family: 'DIN-Regular';
    src: url("../../fonts/DINWebPro.eot");
    /* IE9 Compat Modes */
    src: url("../../fonts/DINWebPro.eot?#iefix") format("embedded-opentype"), url("../../fonts/DINWebPro.woff") format("woff"), url("../../fonts/DINCompPro.ttf") format("truetype");
    font-style: normal;
    font-weight: 400; }
@font-face {
    font-family: 'DIN-Medium';
    src: url("../../fonts/DINWebPro-Medium.eot");
    src: url("../../fonts/DINWebPro-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/DINWebPro-Medium.woff") format("woff"), url("../../fonts/DINCompPro-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'DIN-Bold';
    src: url("../../fonts/DINWebPro-Bold.eot");
    src: url("../../fonts/DINWebPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/DINWebPro-Bold.woff") format("woff"), url("../../fonts/DINCompPro-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'walderwyss-icons';
    src: url("../../fonts/walderwyss-icons.woff");
    font-weight: normal;
    font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
    font-family: 'walderwyss-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/*
Fonts for PDF printing (added for webpack)
*/
@font-face {
    src: url('../../fonts/DINNextLTPro-Light.otf') format("truetype");
}
@font-face {
    src: url('../../fonts/DINNextLTPro-Regular_custom.otf') format("truetype");
}

@font-face {
    font-family: 'OGG-Regular';
    src: url("../../fonts/Ogg-Regular.eot");
    src: url("../../fonts/Ogg-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Ogg-Regular.woff") format("woff"), url("../../fonts/Ogg-Regular.woff2") format("woff2"), url("../../fonts/Ogg-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}
