.component-line-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
}
.component-line {
    margin-top: 1px;
    margin-bottom: 1px;
}

.bg-white-line + .component-line-wrapper {
    background-color: $color-white;
}
