.entry-product {

    h1, .h1 {
        font-family: $sans-serif-bold, sans-serif;
        font-size: 32px;
        line-height: 38px;
    }
    h2, .h2 {
        font-family: $sans-serif-bold, sans-serif;
        font-size: 22px;
        line-height: 25px;
    }

    .container-fluid {
        padding-left:36px;
        padding-right:36px;
    }

    .splide__arrows {
        position: absolute;
        top: calc(50% - 40px);
        width: 100%;
        left: 0;
    }
    .splide__arrow {
        background: transparent;
    }
    .splide__arrow--prev {
        left:-30px;
        opacity: 1;
    }
    .splide__arrow--next {
        right:-30px;
        opacity: 1;
    }
    
}
