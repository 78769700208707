.overview-lawyers {
    .col-lawyer {
        h3 {
            font-family: $sans-serif-bold;
            font-weight: bold;
            font-size: 20px;
        }
        p {
            color: $color-grey;
        }
    }
    .col-lawyer a:hover {
        h3 {
            text-decoration: underline;
        }
    }

    .row-letters {
        li label {
            font-family: $sans-serif-bold;
            font-weight: bold;
            font-size: 25px;
            color: $color-grey;
        }
        li label:hover {
            cursor: pointer;
            color: $color-blue;
        }
        li.active label {
            color: $color-blue;
            text-decoration: underline;
        }
    }


    #indicator {
        display:none;
    }
    #indicator.htmx-request {
        display: flex;
        height:82px;
    }
    #indicator.htmx-request .spinner {
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    #indicator.htmx-request + #load-more-oob {
        display:none !important;
    }

}
.ul-lawyernames li:hover {
    text-decoration: underline;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
