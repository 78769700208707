/*
$mobile-landscape: 30rem; // 480px
$tablet: 40rem; // 640px
$tablet-wide: 48rem; // 768px       => md
$desktop: 64rem; // 1024px          => lg
$widescreen: 80rem; // 1280px       => xl
$XLscreen: 120rem; // 1920px        => xxl



$site-width: $widescreen;
$content-width: 960px;

$site-columns: 12;
$site-gutter-width: 12px;
$site-columns-width: 78px;
$site-columns-space: 20px;

$site-space-medium: 36px;
*/

$color-black:       #000000;
$color-white:       #ffffff;
$color-blue:        #002e60;
$color-blue-light:  #9ab5cf;
$color-blue-light-2:rgba(#002e60, 0.5);
$color-green:       #65953f;
$color-grey:        #898989;
$color-grey-dark:   #51524A;
$color-grey-event:  #a8a99b;
$color-grey-light:  #e4e4e4;
$color-grey-extralight:  #f4f4f4;
$color-grey-extrasuperlight:  #f9f9f9;
$color-beige:       #dcd6b7;
$color-orange:      rgba(179,64,19,.9);
$color-orange-light:rgba(#b34013, 0.5);

$color-future-event: #a8a99b;
$color-past-event: #c9cac2;

$color-gold: #8d8045;
$subpageNavBG: #f4f4f4;

$link-color: $color-blue;

:root {
    --color-grey: #898989;
    --color-blue: #002e60;
    --color-gold: #8d8045;
    --color-grey-extralight: #f4f4f4;
}
.backgroundcolor-grey {
    background-color: var(--color-grey);
}
.backgroundcolor-grey-extralight {
    background-color: var(--color-grey-extralight);
}
.backgroundcolor-blue {
    background-color: var(--color-blue);
}
.backgroundcolor-gold {
    background-color: var(--color-gold);
}


// Colors
/*
$alert-yellow: #ebe16f;
$alert-red: #fbe3e4;
$alert-green: #e6efc2;
$alert-blue: #d5edf8;

$border-color: #ccc;
$disabled-gray: #cfcfcf;
$footerborder: #ebebeb;

$bggrey: #51524a;
$bggreylight: #fafafa;
$bggreymiddle: #f5f5f5;
$ww50-color-gelb: #FDE426;
*/
