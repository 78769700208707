.bg-white { background-color: $color-white; }
.bg-blue { background-color: $color-blue; }
.bg-blue-light { background-color: $color-blue-light; }
.bg-green { background-color: $color-green; }
.bg-grey { background-color: $color-grey; }
.bg-grey-light { background-color: $color-grey-light; }
.bg-grey-extralight { background-color: $color-grey-extralight; }
.bg-grey-event { background-color: $color-grey-event; }
.bg-grey-extrasuperlight { background-color: $color-grey-extrasuperlight; }

.bg-beige { background-color: $color-beige; }
.bg-orange { background-color: $color-orange; }

.bg-future-event { background-color: $color-future-event!important; }
.bg-past-event { background-color: $color-past-event!important; }

.color-white { color: $color-white!important; }
.color-blue { color: $color-blue!important; }
.color-grey { color: $color-grey!important; }
.color-gold { color: $color-gold!important; }
.color-orange { color: $color-orange!important; }
.color-grey-dark { color: $color-grey-dark!important; }

.color-future-event { color: $color-future-event!important; }
.color-past-event { color: $color-past-event!important; }
