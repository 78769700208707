.component-contactPerson {

    .component-contactPerson-headline {
        margin-bottom: 50px;
    }
    .component-contactPerson-row {
        margin-bottom: 32px;
    }
    .component-contactPerson-img {
        width: 150px;
        max-width: 150px;
        // margin-right: 4px;
    }
    .component-contactPerson-text {
        // margin-left: 4px;
    }
    .component-contactPerson-link:hover + .component-contactPerson-text p.color-blue {
        text-decoration: underline;
    }
    .component-contactPerson-text:hover .component-contactPerson-link p.color-blue {
        text-decoration: underline;
    }
}
